.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
  background-color: #0f1535;
  /* height: 40px; */
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 7px;
  /* height: 27px; */
}

.ant-picker {
  background-color: #0f1535;
  /* height: 40px; */
}

.ant-input{
  background-color: #0f1535;
  border-radius: 10px;
}